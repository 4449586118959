import { Dimensions } from "../types/dimensions";

export class RenderDomController {

	private view!: HTMLElement;
	private dimensions!: Dimensions;

	private lastX?: number;
	private lastY?: number;

	private mouseIn!: boolean;
	private pointerDown!: boolean;
	private pointerZoomDown!: boolean;

	private backgroundImage!: HTMLImageElement;
	private foregroundImage!: HTMLImageElement;
	private zoom!: HTMLImageElement;

	async init(view: HTMLElement, dimensions: Dimensions) {
		this.dimensions = dimensions;
		this.view = view;

		// this.view.addEventListener('mouseenter', () => {
		//     if (!this.mouseIn) {
		//         if (this.view && this.zoom) {
		//             this.view.appendChild(this.zoom);
		//             // this.zoomInteractions();
		//         }
		//     }
		//     this.mouseIn = true;
		// });
		// this.view.addEventListener('mouseleave', () => {
		//     if (this.mouseIn) {
		//         if (this.view && this.zoom) {
		//             this.view.removeChild(this.zoom);
		//         }
		//     }
		//     this.mouseIn = false;
		// });
	}

	addBackgroundImage(image: HTMLImageElement) {
		this.backgroundImage = image;
		this.backgroundImage.style.position = 'relative';
        this.backgroundImage.style.maxWidth = '100%';
	}


	addForegroundImage(image: HTMLImageElement, position: { top: number, left: number }) {
		this.foregroundImage = image;
		this.foregroundImage.style.cursor = 'pointer';
		this.foregroundImage.style.top = position.top + 'px';
		this.foregroundImage.style.left = position.left + 'px';
		this.foregroundImage.style.touchAction = 'none';
	}

	addZoom(image: HTMLImageElement) {
		this.zoom = image;
		this.zoom.style.left = parseInt(this.foregroundImage.style.left, 10) + this.foregroundImage.width - 12 + 'px';
		this.zoom.style.top = parseInt(this.foregroundImage.style.top, 10) + this.foregroundImage.height - 12 + 'px';
		this.zoom.style.cursor = 'pointer';
		this.zoom.style.touchAction = 'none';
	}

	interactions() {
		this.foregroundImage.addEventListener('pointerdown', (event) => {
			event.preventDefault();
			event.stopPropagation();
			this.pointerDown = true;
		});
		this.foregroundImage.addEventListener('pointermove', (event) => {
			if (this.pointerDown) {
				event.preventDefault();
				event.stopPropagation();
				const deltaX = event.x - (this.lastX || event.x);
				const deltaY = event.y - (this.lastY || event.y);
				this.lastX = event.x;
				this.lastY = event.y;
				this.foregroundImage.style.left = parseInt(this.foregroundImage.style.left || '0') + deltaX + 'px';
				this.foregroundImage.style.top = parseInt(this.foregroundImage.style.top || '0') + deltaY + 'px';
				this.zoom.style.left = parseInt(this.zoom.style.left || '0') + deltaX + 'px';
				this.zoom.style.top = parseInt(this.zoom.style.top || '0') + deltaY + 'px';
			}
		});

		document.addEventListener('pointerup', (event) => {
			event.preventDefault();
			event.stopPropagation();
			this.pointerDown = false;
			this.pointerZoomDown = false;
			this.lastX = undefined;
			this.lastY = undefined;
		});

		this.zoomInteractions();
	}

	zoomInteractions() {
		const ratio = this.foregroundImage.width / this.foregroundImage.height;
		this.zoom.addEventListener('pointerdown', (event) => {
			event.preventDefault();
			event.stopPropagation();
			this.pointerZoomDown = true;
		});
		document.addEventListener('pointermove', (event) => {
			event.preventDefault();
			event.stopPropagation();
			if (this.pointerZoomDown) {
				const deltaX = event.x - (this.lastX || event.x);
				this.lastX = event.x;
				this.lastY = event.y;
				this.foregroundImage.width += deltaX;
				const tempHeight = this.foregroundImage.height;
				this.foregroundImage.height = this.foregroundImage.width / ratio;
				const deltaY = this.foregroundImage.height - tempHeight;
				this.zoom.style.left = parseInt(this.zoom.style.left || '0') + deltaX + 'px';
				this.zoom.style.top = parseInt(this.zoom.style.top || '0') + deltaY + 'px';
			}
		});
	}


	render() {
		this.view.innerHTML = '';
		if (this.backgroundImage) {
			this.view.appendChild(this.backgroundImage);
		}
		// this.mainContainer.addChild(this.frame);
		if (this.foregroundImage) {
			this.view.appendChild(this.foregroundImage);
		}
		if (this.zoom) {
			this.view.appendChild(this.zoom);
		}
	}
}