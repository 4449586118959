import { RenderController } from "./controllers/render.controller";
import { LoadController } from "./controllers/load.controller";
import { RenderDomController } from "./controllers/render-dom.controller";

const path = 'https://www.juricka.com/apps/skolnik-gallery/';

export class App {
    private renderController: RenderDomController;

    constructor() {
        this.renderController = new RenderDomController();
    }

    async init(view: HTMLElement, upload: HTMLInputElement, image: string, position: { top: number, left: number }) {
        this.renderController.init(view, { width: 640, height: 384 });
        this.renderController.addBackgroundImage(await LoadController.createImage(path + 'public/images/living_room.jpg', 640, false))
        this.renderController.addForegroundImage(await LoadController.createImage(image, 200), position)
        this.renderController.addZoom(await LoadController.createImage(path + 'public/images/resize_2.svg', 20));
        // this.renderController.addFrame();
        this.renderController.render();
        this.renderController.interactions();

        upload.addEventListener('change', (e: any) => {
            e.preventDefault();
            const reader = new FileReader();
            if (!upload?.files?.length) {
                return;
            }
            this.uploadImage(upload?.files[ 0 ]);
        });

        upload.addEventListener('drop', (e: any) => {
            e.preventDefault();
            const reader = new FileReader();
            if (!e.dataTransfer?.files?.length) {
                return;
            }
            this.uploadImage(e.dataTransfer?.files[ 0 ]);
        });
    }

    uploadImage(image: Blob) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = async () => {
            this.renderController.addBackgroundImage(await LoadController.createImage(reader.result as any, 640, false))
            this.renderController.render();
        }
    }
}