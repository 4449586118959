import { css, unsafeCSS } from 'lit';

export const appStyles = css`
  :host {
    position: relative;
    display: inline-block;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  canvas {
    margin: auto;
    display: block;
    max-width: 100%;
  }

  #wrapper {
    position: relative;
    overflow: hidden;
  }
  
  #wrapper img {
    position: absolute;
  }

  .file-drop-area {
    margin-top: 30px;
    position: relative;
    display: flex;
    align-items: center;
    //width: 640px; 
    //max-width: 100%;
    padding: 25px;
    border: 1px dashed rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    transition: 0.2s;
    //&.is-active {
    //  background-color: rgba(0, 0, 0, 0.05);
    //}
  }

  .fake-btn {
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 8px 15px;
    margin-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .file-msg {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    //&:focus {
    //  outline: none;
    //}
  }

`;
