export abstract class LoadController {
    static createImage(src: string, width: number, setHeight: boolean = true): Promise<HTMLImageElement> {
        const img = new Image();
        img.src = src;
        return new Promise((resolve) => {
            img.onload = () => {
                const ratio = width / img.width;
                img.width = width;
                if (setHeight) {
                    img.height = img.height * ratio;
                }
                resolve(img);
            }
        })
    }
}