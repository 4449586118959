import { html, LitElement } from 'lit';
import { appStyles } from './app/styles/styles';
import { customElement, query, property } from 'lit/decorators.js';
import { App } from "./app/app";
import { Input } from "terser-webpack-plugin";

@customElement('skolnik-gallery')
class SkolnikGallery extends LitElement {

    static styles = appStyles;
    private app: App;
    private _image!: string;
    private _top!: number;
    private _left!: number;

    @property()
    set image(image: string) {
        this._image = image;
    }

    @property()
    set top(top: number) {
        this._top = top;
    }

    @property()
    set left(left: number) {
        this._left = left;
    }

    @query('#wrapper')
    wrapper!: HTMLElement;

    @query('#file-upload')
    input!: HTMLInputElement;


    constructor() {
        super();
        this.app = new App();
    }

    firstUpdated() {
        this.init();
    }

    init() {
        this.app.init(this.wrapper, this.input, this._image, { top: this._top, left: this._left });
    }

    render() {
        return html`
            <div id="wrapper"></div>
            <div class="file-drop-area">
                <span class="fake-btn">Vyberte soubor</span>
                <span class="file-msg">nebo jej přetáhněte zde</span>
                <input class="file-input" id="file-upload" type="file" accept="image/*">
            </div>
        `;
    }
}
